import styled from 'styled-components';

const OrderStyles = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  fieldset {
    grid-column: span 2;
    max-height: 600px;
    overflow: auto;
    display: grid;
    gap: 1rem;
    grid-column: span 2;
    align-content: start;
    label {
      display: grid;
      gap: 1rem;
    }
    /* label + label {
      margin-top: 1rem;
    } */
    &.order,
    &.menu {
      grid-column: span 1;
      /* Chrome is weird about Grid and fieldsets, so we add a fixed height to fix it :)  */
      /* height: 100%; */
    }
  }
  /* Other ways to hide besides display none are: scale to 0 or 1px or use zindex or text indent be sure to not throw off screen readers! */
  .mapleSyrup {
    display: none;
  }
  @media (max-width: 900px) {
    fieldset.menu,
    fieldset.order {
      grid-column: span 2;
    }
  }
`;

export default OrderStyles;
